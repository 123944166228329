import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { PhorkIcon } from 'icons/PhorkIcon';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ColorSwatches } from './helpers/ColorSwatches';
import { ColorTheme } from './helpers/ColorTheme';
import { ThemeWrapper } from './helpers/ThemeWrapper';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="postcss/vars" title="Colors" mdxType="PageTitle" />
    <p>{`Clicking on any color block will copy that color ID to your clipboard.`}</p>
    <h2 {...{
      "id": "primary-accent-color"
    }}>{`Primary accent color`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <ColorSwatches\n      joined\n      group=\"state\"\n      swatchHeight={60}\n      swatchWidth={80}\n      themeId={themeId}\n      variant=\"accent\"\n    >\n      <PhorkIcon size={30} />\n    </ColorSwatches>\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <ColorSwatches joined group="state" swatchHeight={60} swatchWidth={80} themeId={themeId} variant="accent" mdxType="ColorSwatches">
        <PhorkIcon size={30} mdxType="PhorkIcon" />
      </ColorSwatches>}
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "success-color"
    }}>{`Success color`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <ColorSwatches\n      joined\n      group=\"state\"\n      swatchHeight={60}\n      swatchWidth={80}\n      themeId={themeId}\n      variant=\"success\"\n    >\n      <PhorkIcon size={30} />\n    </ColorSwatches>\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <ColorSwatches joined group="state" swatchHeight={60} swatchWidth={80} themeId={themeId} variant="success" mdxType="ColorSwatches">
        <PhorkIcon size={30} mdxType="PhorkIcon" />
      </ColorSwatches>}
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "warning-color"
    }}>{`Warning color`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <ColorSwatches\n      joined\n      group=\"state\"\n      swatchHeight={60}\n      swatchWidth={80}\n      themeId={themeId}\n      variant=\"warning\"\n    >\n      <PhorkIcon size={30} />\n    </ColorSwatches>\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <ColorSwatches joined group="state" swatchHeight={60} swatchWidth={80} themeId={themeId} variant="warning" mdxType="ColorSwatches">
        <PhorkIcon size={30} mdxType="PhorkIcon" />
      </ColorSwatches>}
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "danger-color"
    }}>{`Danger color`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <ColorSwatches\n      joined\n      group=\"state\"\n      swatchHeight={60}\n      swatchWidth={80}\n      themeId={themeId}\n      variant=\"danger\"\n    >\n      <PhorkIcon size={30} />\n    </ColorSwatches>\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <ColorSwatches joined group="state" swatchHeight={60} swatchWidth={80} themeId={themeId} variant="danger" mdxType="ColorSwatches">
        <PhorkIcon size={30} mdxType="PhorkIcon" />
      </ColorSwatches>}
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "neutral-color"
    }}>{`Neutral color`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <ColorSwatches\n      joined\n      group=\"state\"\n      swatchHeight={60}\n      swatchWidth={80}\n      themeId={themeId}\n      variant=\"neutral\"\n    >\n      <PhorkIcon size={30} />\n    </ColorSwatches>\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <ColorSwatches joined group="state" swatchHeight={60} swatchWidth={80} themeId={themeId} variant="neutral" mdxType="ColorSwatches">
        <PhorkIcon size={30} mdxType="PhorkIcon" />
      </ColorSwatches>}
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "primary-colors"
    }}>{`Primary colors`}</h3>
    <Playground __position={6} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <ColorSwatches\n      group=\"primary\"\n      swatchHeight={40}\n      swatchWidth={60}\n      themeId={themeId}\n    />\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} withSource="none" mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <ColorSwatches group="primary" swatchHeight={40} swatchWidth={60} themeId={themeId} mdxType="ColorSwatches" />}
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "primary-shades"
    }}>{`Primary shades`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <ColorSwatches\n      direction=\"column\"\n      group=\"primary-shades\"\n      labelHeight={20}\n      swatchHeight={40}\n      swatchWidth={60}\n      themeId={themeId}\n    >\n      <PhorkIcon size={24} />\n    </ColorSwatches>\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <ColorSwatches direction="column" group="primary-shades" labelHeight={20} swatchHeight={40} swatchWidth={60} themeId={themeId} mdxType="ColorSwatches">
        <PhorkIcon size={24} mdxType="PhorkIcon" />
      </ColorSwatches>}
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "background-colors"
    }}>{`Background colors`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <ColorSwatches\n      group=\"background\"\n      swatchHeight={40}\n      swatchWidth={60}\n      themeId={themeId}\n    />\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <ColorSwatches group="background" swatchHeight={40} swatchWidth={60} themeId={themeId} mdxType="ColorSwatches" />}
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "foreground-colors"
    }}>{`Foreground colors`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <ColorSwatches\n      group=\"foreground\"\n      swatchHeight={40}\n      swatchWidth={60}\n      themeId={themeId}\n    />\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <ColorSwatches group="foreground" swatchHeight={40} swatchWidth={60} themeId={themeId} mdxType="ColorSwatches" />}
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transparent-colors"
    }}>{`Transparent colors`}</h2>
    <Playground __position={10} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <ColorSwatches\n      group=\"transparent\"\n      swatchHeight={40}\n      swatchWidth={60}\n      themeId={themeId}\n    />\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <ColorSwatches group="transparent" swatchHeight={40} swatchWidth={60} themeId={themeId} mdxType="ColorSwatches" />}
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "primary-theme"
    }}>{`Primary theme`}</h2>
    <Playground __position={11} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => <ColorTheme themeId={themeId} variant=\"primary\" />}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">{({
          themeId
        }) => <ColorTheme themeId={themeId} variant="primary" mdxType="ColorTheme" />}</ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "secondary-theme"
    }}>{`Secondary theme`}</h2>
    <Playground __position={12} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => <ColorTheme themeId={themeId} variant=\"secondary\" />}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">{({
          themeId
        }) => <ColorTheme themeId={themeId} variant="secondary" mdxType="ColorTheme" />}</ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "tertiary-theme"
    }}>{`Tertiary theme`}</h2>
    <Playground __position={13} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => <ColorTheme themeId={themeId} variant=\"tertiary\" />}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">{({
          themeId
        }) => <ColorTheme themeId={themeId} variant="tertiary" mdxType="ColorTheme" />}</ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "contrast-theme"
    }}>{`Contrast theme`}</h2>
    <Playground __position={14} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => <ColorTheme themeId={themeId} variant=\"contrast\" />}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      PhorkIcon,
      PageTitle,
      ColorSwatches,
      ColorTheme,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">{({
          themeId
        }) => <ColorTheme themeId={themeId} variant="contrast" mdxType="ColorTheme" />}</ThemeWrapper>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      